import React            from 'react'
import Helmet           from 'react-helmet'

import config           from '../../../../data/site-config'

import Layout           from '../../../components/layout'
import MainWrapper      from '../../../components/MainWrapper/MainWrapper'
import PageHero         from '../../../components/PageHero'

const NeueBuildPage = () => (
  <Layout>
    <Helmet
      title={`NeueBuild • Real Estate Startup for New Build Homes`}
      meta={[
        { name: 'description', content: 'A family of realtors creates the Zillow for new build homes startup to serve a gap in the online real estate market.' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/portfolio/neue-build/` }
      ]}
      bodyAttributes={{
        class: 'dark-theme'
      }}
    />

    <PageHero
      bgColor="#00BAFE"
      bgImage="/portfolio/neue-build/neue-build-bg.jpg">
        <img src="/client-logos/neue-build-logo.svg" className="" alt="Neue Build logo" />
        <h1 className="t-headline black">NeueBuild&mdash;<span className="o-50">real estate startup for new build homes</span></h1>
    </PageHero>

    <MainWrapper>

      <section className="pv5 pv6-ns bg-near-white tc">
        <span className="t-small-caps neuebuild">TL;DR</span>
        <h2 className="t-mega measure-tight center">A real estate startup for taking advantage of a market gap</h2>
      </section>

      <div className="pv5 pv6-ns row narrow">
        <h3 className="f2">How to Start a Startup</h3>
        <p>We began working with a family of realtors to develop a unique open niche in the real estate market.</p>
        <p>Searching for and purchasing a new construction home is different than other real estate properties. The majority of new construction homes are not listed on websites like Zillow or RedFin. So, the new homes are found by other means.</p>
        <p>We stepped in to develop a brand, user experience, and technology stack to make the search for new build properties as easy to use as any other online real estate marketplace.</p>

        <h3 className="mt5">Our approach to startup technology</h3>
        <p>Technology can make or break a startup. Startup apps need to be stable, easy to maintain, capable of growth, and be clearly documented. At Spacetime, we always use common, well-known technologies. We avoid any type of "proprietary" code or platforms and opt for open source code.</p>
        <p>Here are some of the tools and services we used for NeueBuild:</p>
        <ul className="lh-copy">
          <li>Ruby on Rails</li>
          <li>React JS</li>
          <li>Heroku</li>
          <li>Postgres</li>
          <li>Amazon S3</li>
          <li>Stripe</li>
          <li>Frontend development</li>
          <li>Mobile first development</li>
          <li>Responsive web design (RWD)</li>
          <li>Google Analytics</li>
          <li>MailChimp integration</li>
        </ul>
      </div>

      <div className="row narrow">

        <h3 className="f2">Branding</h3>
        <p>We worked to create the business name, logo, and brand identity.</p>
        <img src="/portfolio/neue-build/neue-build-logo.png" className="center db mw5" alt="Developing the NeueBuild brand logo" />

        <hr className="mv5 mv6-ns bw4 b--neuebuild o-10" />

        <h3 className="f2">Moodboard &amp; Style Tiles</h3>
        <p>The branding and visual design process begins with a moodboard and style tiles.</p>
      </div>

      <img src="/portfolio/neue-build/neue-build-style-tiles.jpg" className="center db mv6" alt="NeueBuild web design style tiles" />

      <div className="row narrow mv6">

        <h3 className="f2">Web &amp; App Design</h3>
        <p>We designed and developed the entire NeueBuild product from idea to market.</p>

      </div>

      <img src="/portfolio/neue-build/neue-build-real-estate-web-design.jpg" className="mv5 w-90 mw8 center db deep-shadow" alt="NeueBuild website homepage" />

      <img src="/portfolio/neue-build/neue-build-real-estate-website-app-design.jpg" className="mv5 w-90 mw8 center db deep-shadow" alt="NeueBuild web design features" />

      <div className="bg-banded flex items-start justify-center mv5 mv6-l">
        <img src="/portfolio/neue-build/neue-build-real-estate-startup.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="NeueBuild home search and map" />
        <img src="/portfolio/neue-build/neue-build-real-estate-ux-ui.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="NeueBuild detail page for a single home" />
      </div>

      <div className="pv5 pv6-ns row narrow">
        <h3>Is This The End?</h3>

        <p className="o-80"><em>Note: NeueBuild recently shutdown in October 2019. Maybe it will resurface, we shall see.</em></p>
      </div>



    </MainWrapper>

  </Layout>
)

export default NeueBuildPage
